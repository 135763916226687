import type { PropsWithChildren } from 'react';
import { Icon, IconName } from '../ui/Icon';

export type ModalTitleProps =  PropsWithChildren<{
  onClickClose?: () => void;
}>;

export const ModalTitle = ( { children, onClickClose } : ModalTitleProps ) => {

  return(
    <div className={ 'pt-1.5 lg:pt-0 text-[#ABB0C7] flex flex-row items-center' }>
      <div
        className={ 'lg:opacity-0 p-[30px] cursor-pointer lg:pointer-events-none' }
        onClick={ onClickClose }>
        <Icon
          name={ IconName.ChevronLeft }
          className={ 'icon-size-6' }
        />
      </div>
      <div className={ 'text-center text-base line-clamp-2 font-semibold flex-1' }>
        { children }
      </div>
      <div
        className={ 'opacity-0 lg:opacity-100 p-[30px] cursor-pointer pointer-events-none lg:pointer-events-auto' }
        onClick={ onClickClose }>
        <Icon
          name={ IconName.Xmark }
          className={ 'icon-size-6' }
        />
      </div>
    </div>
  )
}
ModalTitle.displayName = 'ModalTitle';