import type { PropsWithChildren } from 'react';
import { cn } from '~/utils/cn';

export type ModalContentProps = PropsWithChildren<{
  className?: string;
}>;

export const ModalContent = ( { children, className }: ModalContentProps ) => {

  return (
    <div className={ cn( 'px-6 py-2.5 overflow-auto', className ) }>
      { children }
    </div>
  )
}
ModalContent.displayName = 'ModalContent';  